import api from "../../../base/utils/request";

export const getIndexData = (data) => {
  return api({
    url: '/admin/app_design/project/index',
    method: 'post',
    data
  })
}

export const updatePlatformStatus = (data) => {
  return api({
    url: '/admin/app_design/project/updateStatus',
    method: 'post',
    data
  })
}
