<template>
  <div class="app-design" v-loading="loading">
    <el-card v-for="(item, index) in list" :key="item.id">
      <div slot="header" class="flex-between">
        <span class="card-title flex">{{item.platform_text}}</span>
        <div v-if="item.platform === 'h5'" class="card-switcher">
          <span>状态：</span>
          <el-switch v-model="item.status" :active-value="1" :inactive-value="0" @change="handleSwitch(item.id, $event)"></el-switch>
        </div>
      </div>
      <div class="card-content" v-if="item.is_bind">
        <span class="card-time">更新时间：{{item.update_time}}</span>
        <div class="card-btn-group">
          <el-button :disabled="backup.data.disabled" @click="backup.show = true" size="small">
            备份版本</el-button>
          <el-button @click="toDesign(item.platform, item.id)" size="small" type="primary">装修页面</el-button>
        </div>
      </div>
      <div class="card-content" v-else>
        <div class="no-bind flex-center">
          <span>当前未绑定小程序</span>
          <router-link :to="{name: 'AppletBaseInfo'}"><u>前往绑定</u></router-link>
        </div>
      </div>
    </el-card>
    <backup-dialog v-model="backup.show" />
  </div>
</template>

<script>
  import {getIndexData, updatePlatformStatus} from "../api";
import BackupDialog from "../components/Index/BackupDialog";

export default {
  components: { BackupDialog },
  data() {
    return {
      loading: true,
      list: [],
      backup: {
        show: false,
        data: {
          disabled: true,
        },
      },
    };
  },
  watch: {
    /* 两个路由共用一个页面组件文件，相互切换内容不刷新，所以如下处理 */
    '$route.name': {
      handler(val) {
        getIndexData({
          platform: val.indexOf('H5') !== -1 ? 'h5' : (val.indexOf('DesignApp') !== -1 ? 'mp-weixin' : 'all')
        })
          .then((res) => {
            this.list = res.data;
            this.loading = false;
          })
          .catch((err) => {
            this.loading = false;
          });
      },
      immediate: true
    }
  },
  created() {

  },
  methods: {
    toDesign(platform, id) {
      /* 进入页面装修后，重新请求缓存的分类数据 */
      this.$store.commit('appDesign/setStateAttr', [{
        key: 'lastFetchTimestampRecord', val: {}
      }])
      let routerData = this.$router.resolve({
        name: "AppDesignPage",
        params: { platform: platform, id: id },
      });
      window.open(routerData.href, "_blank");
    },
    handleSwitch(id, e) {
      this.loading = true;
      updatePlatformStatus({id, status: e}).then(res => {
        this.$message.success(res.msg)
        this.loading = false;
      }).catch(err => {
        this.loading = false;
      })
    }
  },
};
</script>

<style scoped lang="scss">
.app-design {
  margin: -$space;
  padding: 4px;
/*  background-color: #f5f6f7;*/
  min-height: 100%;
  flex: 1;

  .el-card,
  ::v-deep.el-card {
    & + .el-card {
      margin-top: 24px;
    }

    .el-card__header {
      padding: 24px 32px 20px;
    }
  }

  .card-title {
    font-size: 16px;
    font-weight: 500;
    color: rgba(0, 0, 0, 0.85);
    line-height: 16px;
    display: inline-flex;

    &::before {
      content: "";
      background-color: $primary;
      width: 3px;
      height: 16px;
      display: inline-block;
      margin-right: 8px;
    }
  }

  .card-switcher {
    display: inline-flex;
    align-items: center;
    font-size: 16px;
    line-height: 16px;
  }

  .card-content {
    display: flex;
    justify-content: space-between;

    .card-time {
      font-size: 14px;
      color: rgba(0, 0, 0, 0.65);
      line-height: 14px;
    }

    .card-btn-group {
      margin-top: 6px;
    }

    .no-bind {
      font-size: 14px;
      color: rgba(0, 0, 0, 0.85);
      line-height: 14px;
      height: 62px;
      margin: 0 auto;

      .el-link,
      a {
        margin-left: 5px;
      }
    }
  }
}
</style>
