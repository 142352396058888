<template>
  <el-dialog class="backup-dialog dialog-vertical" :visible="value" title="备份版本" width="729px"
    @close="cancel">
    <p class="info">你可选择将以下备份版本设为主版本，最多保存最近备份的 5 个版本。</p>
    <el-radio-group v-model="radioValue">
      <el-table :data="test" class="thead-light" :default-sort="{prop: 'time', order: 'descending'}" stripe
        @row-click="handleChoose">
        <el-table-column label="" width="60px">
          <template slot-scope="{row}">
            <el-radio :label="row.id"><span></span></el-radio>
          </template>
        </el-table-column>
        <el-table-column label="备份名称" prop="name" show-overflow-tooltip></el-table-column>
        <el-table-column label="备份时间" prop="time" width="220" sortable></el-table-column>
        <el-table-column label="操作" width="120px">
          <template slot-scope="{row}">
            <el-button-group>
              <el-button type="text" @click.stop="setPrimary">设为主版本</el-button>
            </el-button-group>
          </template>
        </el-table-column>
      </el-table>
    </el-radio-group>
    <template #footer>
      <el-button size="small" @click="cancel">关闭</el-button>
    </template>
  </el-dialog>
</template>

<script>
export default {
  name: "BackupDialog",
  props: {
    value: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      radioValue: "",
      test: [
        { id: 2, name: "xxx", time: "2012-02-28 12:47:33" },
        { id: 4, name: "xxx", time: "2012-02-28 12:44:33" },
      ],
    };
  },
  methods: {
    cancel() {
      this.$emit("input", false);
    },
    setPrimary() {},
    handleChoose(row, column, event) {
      this.radioValue = row.id;
    },
  },
};
</script>

<style lang="scss" scoped>
.backup-dialog {
  .info {
    font-size: 12px;
    color: #c0c4cc;
    line-height: 12px;
    margin-bottom: $space;
  }

  .el-dialog__footer,
  ::v-deep.el-dialog__footer {
    text-align: center;
  }

  .el-radio-group {
    width: 100%;
  }
}
</style>
